$prefix: cooking-v2;

.#{$prefix} {
    &__main_bar {
        background: #252525;
        color: white;
        height: 5rem;
        font-size: 3.75rem;
        font-weight: 700;
    }

    &__sub_heading {
        font-size: 2.4rem;
        letter-spacing: -1.9px;
        font-weight: 700;
        margin: 0.75rem 0px 0px 0px;
        justify-content: center;
        align-items: center;

        p {
            margin: 0px 0.5rem 0px 0.5rem;
        }
    }

    &__delivery_heading {
        margin-top: 1.75rem;
    }

    &__sub_heading_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__priority_tag {
        background-color: #FFDE66;
        font-size: 1.5rem;
        padding: 0.25rem 0.5rem;
        border: 1px solid black;
        border-radius: 20px;
        margin: 0.75rem 0px;
    }

    &__incoming_tag {
        background-color: #774FCF;
        font-size: 1.5rem;
        padding: 0.25rem 0.5rem;
        border-radius: 20px;
        margin: 0.75rem 0px;
        color: white;
        text-align: center;
        width: 12rem;
    }

    &__cook_tag {
        background-color: #F4594F;
        font-size: 0.9rem;
        padding: 0.1rem 0.25rem;
        color: white;
        border-radius: 20px;
        margin-left: 0.5rem;
        letter-spacing: 0px;
    }

    &__items {
        font-size: 2.2rem;
        letter-spacing: -0.8px;
        padding: 0.25rem;
        text-align: left;
        align-items: center;
        border: 1px solid black;
        justify-content: space-between;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        margin-top: 0.5rem;
    }

    &__item_column {
        border-right: 1px solid black;
        height: 51.5vh;
        background: #F4F4F4;
        @media (min-width: 1920px) {
            height: 56.1vh;
        }
    }

    &__item_column_full_screen {
        border-right: 1px solid black;
        background: #F4F4F4;
        height: 62.5vh;
        @media (min-width: 1920px) {
            height: 63.5vh;
        }
    }

    &__insta_service_production_container {
        padding: 0;
        background: black;
        height: 31vh;
        overflow-x: scroll;
        @media (min-width: 1920px) {
            height: 29.6vh;
        }
    }

    &__timer {
        width: 5.25rem;
        overflow: hidden;
        letter-spacing: -2px;
    }

    &__component_name {
        font-size: 1.65rem;
        max-width: 15rem;
        overflow: hidden;
        white-space: nowrap;
    }

    &__warmer_sku_items_table {
        font-size: 2rem;
        width: 100%;
    }

    &__card_header {
        color: white;
        height: 3.75rem;
        font-size: 2.25rem;
        font-weight: 700;
        text-align: left;
        padding-left: 0.5rem;
        padding-top: 0.5rem;

        @media (min-width: 1920px) {
            height: 4rem;
            font-size: 2.5rem;
        }

        .top-up {
            background-color: black;
        }
    }

    &__warmer_sku_category {
        border: 2px solid white;
        font-size: 2rem;
        text-align: left;
        margin: 0 0.5rem;
    }

    &__warmer_category_name {
        background: #131415;
        color: white;
        padding: 0.8rem;
        text-align: center;
    }

    &__warmer_sku_items {
        display: flex;
        padding: 0.5rem 0.45rem;
        color: #303234;
        min-width: 22.5rem;
        border: 1px black;
        font-family: 'InterBold';
        font-size: 1.375rem;
    }

    &__print {
        border: 0;
        width: 30px;
        height: 30px;
        padding: 5px;
        margin-left: 1rem;
    }

    &__warmer_stock_container {
        padding: 0;
        background: #F4594F33;
        height: calc(100vh - 75px);
        flex-grow: 1;
    }

    &__warmer_item_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &__warmer_item_name {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

    &__oos-tag {
        font-style: normal;
        overflow: hidden;
        background-color: red;
        color: white;
        font-size: 1.25rem;
        padding: 7px 3px;
        border-radius: 8px;
        margin-left: 5px;
        min-width: 5px;
        flex-shrink: 0;
    }

    &__card_container {
        height: 25vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    &__table {
        max-height: 100vh;
        overflow: scroll;
    }

    &__table::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }

    &__webcam-area {
        background-color: #e6e6e6;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .webcam {
            height: 2px;
            width: 2px;
        }
        .img-to-show {
            height: 12.5rem;
            width: 12.5rem;
            object-fit: fill;
        }
    }
}