.pa {
    padding: auto;
}
.pxl {
    padding: 66px;
}
.pl {
    padding: 34px;
}
.pm {
    padding: 18px;
}
.ps {
    padding: 10px;
}
.pxs {
    padding: 6px;
}
.p0 {
    padding: 0px;
}
.ptxl {
    padding-top: 66px;
}
.ptl {
    padding-top: 34px;
}
.ptm {
    padding-top: 18px;
}
.pts {
    padding-top: 10px;
}
.ptxs {
    padding-top: 6px;
}
.pt0 {
    padding-top: 0px;
}
.pbxl {
    padding-bottom: 66px;
}
.pbl {
    padding-bottom: 34px;
}
.pbm {
    padding-bottom: 18px;
}
.pbs {
    padding-bottom: 10px;
}
.pbxs {
    padding-bottom: 6px;
}
.pb0 {
    padding-bottom: 0px;
}
.plxl {
    padding-left: 66px;
}
.pll {
    padding-left: 34px;
}
.plm {
    padding-left: 18px;
}
.pls {
    padding-left: 10px;
}
.plxs {
    padding-left: 6px;
}
.pl0 {
    padding-left: 0px;
}
.prxl {
    padding-right: 66px;
}
.prl {
    padding-right: 34px;
}
.prm {
    padding-right: 18px;
}
.prs {
    padding-right: 10px;
}
.prxs {
    padding-right: 6px;
}
.pr0 {
    padding-right: 0px;
}
.ma {
    margin: auto;
}
.mla {
    margin-left: auto;
}
.mra {
    margin-right: auto;
}
.m0a {
    margin: 0 auto;
}
.mxl {
    margin: 66px;
}
.ml {
    margin: 34px;
}
.mm {
    margin: 18px;
}
.ms {
    margin: 10px;
}
.mxs {
    margin: 6px;
}
.m0 {
    margin: 0px;
}
.mtxl {
    margin-top: 66px;
}
.mtl {
    margin-top: 34px;
}
.mtm {
    margin-top: 18px;
}
.mts {
    margin-top: 10px;
}
.mtxs {
    margin-top: 6px;
}
.mt0 {
    margin-top: 0px;
}
.mbxl {
    margin-bottom: 66px;
}
.mbl {
    margin-bottom: 34px;
}
.mbm {
    margin-bottom: 18px;
}
.mbs {
    margin-bottom: 10px;
}
.mbxs {
    margin-bottom: 6px;
}
.mb0 {
    margin-bottom: 0px;
}
.mlxl {
    margin-left: 66px;
}
.mll {
    margin-left: 34px;
}
.mlm {
    margin-left: 18px;
}
.mls {
    margin-left: 10px;
}
.mlxs {
    margin-left: 6px;
}
.ml0 {
    margin-left: 0px;
}
.mrxl {
    margin-right: 66px;
}
.mrl {
    margin-right: 34px;
}
.mrm {
    margin-right: 18px;
}
.mrs {
    margin-right: 10px;
}
.mrxs {
    margin-right: 6px;
}
.mr0 {
    margin-right: 0px;
}
.dmk-button-height {
    min-height: 37.9px;
}
