$prefix: bag_scan;

.#{$prefix} {
  &__qr-reader {
    height: 100%;
  }

  &__qr-reader section{
    height: 100% !important;
    width: auto !important;
  }

  &__qr-reader section section{
    height: 100% !important;
    width: auto !important;
  }

  &__qr-reader section section div{
    border-width: 16rem 3rem !important;
    --size: 32px;
    background:
            linear-gradient(#fec700 var(--size), transparent 0 calc(100% - var(--size)), #fec700 0) 0 0 / 5px 100%,
            linear-gradient(#fec700 var(--size), transparent 0 calc(100% - var(--size)), #fec700 0) 100% 0 / 5px 100%,
            linear-gradient(to right, #fec700 var(--size), transparent 0 calc(100% - var(--size)), #fec700 0) 0 0 / 100% 5px,
            linear-gradient(to right, #fec700 var(--size), transparent 0 calc(100% - var(--size)), #fec700 0) 0 100% / 100% 5px;
    background-repeat: no-repeat;
    box-shadow: none !important;
  }

  &__scan-text {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    background-color: #fec700;
    width: 200px;
    z-index: 2;
    position: absolute;
    top: 24rem;
    left: 50%;
    transform: translate(-50%, 0);

    &.uploading {
      padding: 5px;
      width: 150px;
      border-radius: 10px;
      z-index: 1;
      position: absolute;
      top: 22rem;
      background-color: #303234;
      color: white;
    }
  }

  &__upload-status-text {
    padding: 10px;
    font-size: 15px;
    background-color: #03c875;
    z-index: 1;

    &.error {
      background-color: #f4594f;
      color: white;
    }
  }

  &__request-image {
    margin-top: 10%;
    margin-left: 15%;
    height: 80%;
    width: 70%;

    img {
      height: 100%;
      width: 100%;
    }
  }
}