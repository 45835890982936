.badge {
    display: inline-block;
    background-color: $whitelabel-light;
    color: $white;
    border-radius: 5px;
    font-size: $small;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    padding: 3px;

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 5px;
    padding-left: 5px;
}
