.cashier-pickup-order-card {
    flex: 1 0 500px;
    box-sizing: border-box;
    margin: 1rem 0.4em;
    // background-color: white;
    max-width: calc(25% - 1em);
    // height: 10vh;
    box-shadow: 0 1px 12px -1px #777;

    &__pickup-order {
        max-width: 80%;
    }

    &__cash-reminder {
        color: #f08080;
        font-style: italic;
        margin-top: 20px;
    }
}
