$prefix: operation-dashboard;

.#{$prefix} {
    &__card {
        background-color: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 12px;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
        padding: 20px;
        margin: 20px auto;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            transform: scale(1.03);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
    }

    &__card_header {
        font-size: 1.75rem;
        font-weight: 700;
        color: #2c3e50;
        margin-bottom: 12px;
        text-align: center;
        border-bottom: 3px solid #3498db;
        padding-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
    }

    &__card ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__card li {
        font-size: 1.25rem;
        color: #34495e;
        padding: 8px 0;
        border-bottom: 1px solid #ecf0f1;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            color: #3498db;
            background-color: #f4f9fb;
        }
    }

    &__card::-webkit-scrollbar {
        width: 10px;
    }

    &__card::-webkit-scrollbar-thumb {
        background-color: #bdc3c7;
        border-radius: 5px;
    }

    &__card::-webkit-scrollbar-thumb:hover {
        background-color: #7f8c8d;
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 12px 0;
        gap: 20px;
        padding: 10px 20px;
        background-color: #f7f9fa;
        border-radius: 10px;
        border: 1px solid #e0e0e0;
    }

    &__filter {
        flex: 1;
        margin: 8px;
        min-width: 220px;
        max-width: 300px;

        select {
            background-color: #f9f9f9;
            border: 1px solid #dcdcdc;
            border-radius: 8px;
            padding: 12px;
            font-size: 1rem;
            color: #2c3e50;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease, box-shadow 0.3s ease;

            &:focus {
                outline: none;
                border-color: #3498db;
                box-shadow: 0 0 4px rgba(52, 152, 219, 0.5);
            }
        }

        .react-select__multi-value {
            background-color: #3498db;
            color: #ffffff;
            border-radius: 6px;
            padding: 3px 8px;
            margin: 3px;
            font-size: 0.9rem;
        }

        .react-select__multi-value__remove {
            color: #ffffff;

            &:hover {
                color: #ff6b6b;
            }
        }
    }
    
    &__webcam-area {
        background-color: #e6e6e6;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .webcam {
            height: 2px;
            width: 2px;
        }
        .img-to-show {
            height: 12.5rem;
            width: 12.5rem;
            object-fit: fill;
        }
    }
}
