$prefix: current-production;

.#{$prefix} {
    &__webcam-area {
        background-color: #e6e6e6;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .webcam {
            height: 2px;
            width: 2px;
        }

        .img-to-show {
            height: 200px;
            width: 200px;
            object-fit: fill;
        }
    }

    margin-top: 24px;
    overflow-y: hidden;
    white-space: nowrap;

    &__table {
        text-align: left;
    }

    &__column {
        display: inline-block;
        width: 315px;
        margin: 0 12.5px 48px;
        text-align: center;
        font-size: 24px;
        vertical-align: top;
    }

    &__wide-column {
        display: inline-block;
        width: 540px;
        margin: 0 12.5px 48px;
        text-align: center;
        font-size: 24px;
        vertical-align: top;
    }

    &__column-block {
        background-color: $white;
        padding: 15px 12px;
        margin-bottom: 8px;
        font-size: 20px;
        font-family: $brand-boldest-font;

        &.header {
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: space-between;

            .cycle-number {
                width: 58px;
                height: 58px;
                background-color: black;
                font-size: 36px;
                color: white;
                border-radius: 8px;
                line-height: 58px;

                &.border {
                    width: 8px;
                }
            }

            &.red {
                color: $whitelabel-red;

                .cycle-number {
                    background-color: $whitelabel-red;
                }
            }
        }

        &.time {
            &.red {
                color: $whitelabel-red;
            }

            &.green {
                color: #03c875;
            }

            &.yellow {
                color: #fcab3f;
            }
        }

        &.items {
            min-height: 625px;
        }

        &.dine-in {
            background-color: transparent;
            padding: 0;
            height: 800px;
            width: 540px;
            display: flex;
            justify-content: space-between;
        }
    }

    &__column-divider {
        display: inline-block;
        margin: 0 12.5px;
        border: 1px solid #eee;
        height: 960px;
    }

    &__meals {
        height: 510px;
        margin-top: 15px;
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        overflow: hidden;

        &.empty {
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        &.items {
            height: 420px;
        }

        &.addons {
            height: 290px;
        }

        &.wide-column-1 {
            width: 260px;
            height: 800px;
            overflow: auto;
        }
    }

    &__cell {
        width: 50%;

        &-span {
            height: 45px;
            min-width: 45px;
            background-color: $white;
            color: $white;
            line-height: 49px;
            font-size: 24px;
            position: relative;
            display: inline-block;

            &.sku {
                min-width: 86px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            &.total {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
                background-color: #fff0ea;
                color: #484848;
                font-size: 20px;
                padding-right: 5px;
                font-weight: bold;
                line-height: 49px;
                vertical-align: bottom;
            }

            &.checked {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }

            &.padding-right {
                padding-right: 20px;
            }
        }

        &.orange {
            .sku {
                background-color: $warmer-color;
            }

            .total {
                background-color: #fff0ea;
            }

            .checked {
                background-color: $warmer-color;
            }
        }

        &.blue {
            .sku {
                background-color: #4154ff;
            }

            .total {
                background-color: #eceeff;
            }

            .checked {
                background-color: #4154ff;
            }
        }

        &.green {
            .sku {
                background-color: #03c875;
            }

            .total {
                background-color: #e6faf1;
            }

            .checked {
                background-color: #03c875;
            }
        }

        &.indigo {
            .sku {
                background-color: $route-color;
            }

            .total {
                background-color: #f1edfa;
            }

            .checked {
                background-color: $route-color;
            }
        }

        &.butterscotch {
            .sku {
                background-color: #fcab3f;
            }

            .total {
                background-color: #fff7ec;
            }

            .checked {
                background-color: #fcab3f;
            }
        }

        &.black {
            .sku {
                background-color: #1a1a1a;
            }

            .total {
                background-color: #fafafa;
            }

            .checked {
                background-color: #1a1a1a;
            }
        }

        &.dine-in {
            display: flex;
            width: 100%;

            .#{$prefix}__cell-span {
                &.sku {
                    width: 86px;
                    flex: 0;
                }

                &.timer {
                    flex: 1;
                    color: $black;
                    text-align: right;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }

                &.delay {
                    color: $whitelabel-red;
                    background-color: #ffdbe4;
                }
            }
        }
    }

    &__print {
        padding: 6px 12px;
        border: 0;
        background-color: transparent;

        .icon-print-medium {
            width: 20px;
            height: 20px;
        }
    }

    .oven {
        border-bottom: 2px solid #eeeeee;
        font-size: 26px;

        div {
            padding: 10px;
            margin-bottom: 15px;

            &:first-child {
                border-right: 1px solid #eeeeee;
            }
        }
    }

    .addons-separator {
        flex: 100%;
        margin: 15px 0 21px;

        span {
            background-color: #f8f8f8;
            padding: 7.5px;
            border-radius: 50px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                height: 1px;
                width: 100px;
                right: 100%;
                background-color: #d7d7d7;
                top: 50%;
            }

            &:after {
                content: '';
                position: absolute;
                height: 1px;
                width: 100px;
                left: 100%;
                background-color: #d7d7d7;
                top: 50%;
            }
        }
    }

    .btn {
        font-size: 14px;
        width: 100%;
        padding: 18px 10px;
    }

    .icon-bell {
        width: 40px;
        height: 40px;
    }

    .icon-timer {
        width: 97px;
        height: 97px;
    }

    .icon-print-small {
        width: 17px;
        height: 16px;
    }

    .icon-check {
        width: 18px;
        height: 16px;
    }

    .icon-hourglass {
        width: 109px;
        height: 109px;
    }
}
