.route {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 12px;
    align-items: center;
    margin: 34px auto 280px;
    padding: 0 34px;
    max-width: 670px;
    font-size: 16px;

    &__header {
        font-family: InterSemiBold;
        background-color: #d34a7d;
        border-radius: 5px;
        color: #fff;
        padding: 7px 10px;
        grid-column: 1 / 3;
    }

    &__meal-sku {
        font-family: InterSemiBold;
    }

    &__meal-count {
        background-color: #eee;
        border-radius: 5px;
        padding: 10px;
        font-family: InterSemiBold;
    }

    &__selection {
        display: flex;
        flex-flow: row wrap;
        max-width: 610px;
        padding: 0 34px;
        margin: 0 auto;

        .production__drawer-button {
            width: 51px;
            height: 35px;
            line-height: 35px;
            font-size: 18px;
        }
    }

    @include breakpoint($mobile) {
        font-size: 20px;

        &__selection {
            padding: 0;
        }
    }
}

.label-selection {
    margin: 0 5px;

    &.selected,
    &:hover {
        color: $whitelabel-green;
        text-decoration: underline;
    }
}
