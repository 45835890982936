.all-orders {
    &__table_row {
        cursor: pointer; 
        margin-left: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px rgba(223, 224, 225, 1) solid;

        @media (min-width: 1920px) {
            font-size: 18.5px;
        }
    }

    &__table_row:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    &__modal_table_row {
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    &__modal_table_body {
        font-size: 20px;
        margin: 36px;
        @media (min-width: 1920px) {
            font-size: 24px;
            margin: 48px;
        }
    }

    &__modal_table_title {
        color: var(--Red-Red-Primary, #F4594F);
        font-family: Inter;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__modal_close_button {
        position: fixed;
        z-index: 999;
        right: 10%;
        top: 3%;
        width: 50px;
        height: 50px;
        border-radius: 30px;
        font-size: 30px;
        background-color: white;
        border: none;
        @media (min-width: 1920px) {
            width: 75px;
            height: 75px;
            border-radius: 50px;
            font-size: 50px;
            border: none;
        }
    }

    &__modal_dine_in_icon {
        width: 7%;
        margin-left: 32px;
        @media (min-width: 1920px) {
            width: 5%;
            margin-left: 45px;
        }
    }

    &__dine_in_icon {
        width: 30px;
    }

    &__modal_take_away_icon {
        width: 4%;
        margin-left: 32.5px;
        @media (min-width: 1920px) {
            width: 3%;
            margin-left: 42.5px;
        }
    }

    &__take_away_icon {
        width: 20px;
    }

    &__modal_grab_food_icon {
        width: 7.5%;
        margin-left: 18px;
        @media (min-width: 1920px) {
            width: 5%;
            margin-left: 34px;
        }
    }

    &__modal_aggregator_icon {
        width: 7.5%;
        @media (min-width: 1920px) {
            width: 5%;
        }
    }

    &__grab_food_icon {
        width: 30px;
    }

    &__modal_food_panda_icon {
        width: 7%;
        margin-left: 22.5px;
        @media (min-width: 1920px) {
            width: 4.5%;
            margin-left: 37.5px;
        }
    }

    &__food_panda_icon {
        width: 30px;
    }

    &__modal_shopee_food_icon {
        width: 7.5%;
        margin-left: 20px;
        @media (min-width: 1920px) {
            width: 5%;
            margin-left: 35px;
        }
    }

    &__shopee_food_icon {
        width: 30px;
    }
    
    &__modal_delivery_icon {
        width: 6%;
        margin-left: 32px;
        filter: invert(1);
        @media (min-width: 1920px) {
            width: 4%;
            margin-left: 45px;
        }
    }

    &__delivery_icon {
        width: 30px;
        filter: invert(1);
    }

    &__view-all-orders {
        width: 150px;
        height: 55px;
        padding: 16px;
        border: 1px solid rgba(160, 162, 164, 1) !important;
        border-radius: 8px;
        font-size: 19px;
        font-weight: 700;
        line-height: 20px;
        color: rgba(112, 86, 181, 1);
        background-color: white;
    }

    &__webcam-area {
        background-color: #e6e6e6;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .webcam {
            height: 2px;
            width: 2px;
        }
        .img-to-show {
            height: 12.5rem;
            width: 12.5rem;
            object-fit: fill;
        }
    }
}
