[id^='switch'] {
    display: none;
}

.label[for='rcp-dmk-switch'] {
    margin: 0 10px;
    font-size: 16px;
    font-family: sans-serif;
    color: $whitelabel-green;
}

.toggle-container {
    display: inline-block;
}

.input + .toggle {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
}

.input + .toggle {
    padding: 2px;
    width: 52px;
    height: 32px;
    background-color: #c4cbc7;
    border-radius: 31px;
    transition: background 0.4s;
    display: inline-block;
    vertical-align: middle;
}
.input + .toggle:before,
.input + .toggle:after {
    display: block;
    position: absolute;
    content: '';
}
.input + .toggle:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #c4cbc7;
    border-radius: 31px;
    transition: background 0.4s;
}
.input + .toggle:after {
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: 28px;
    background-color: $white;
    box-shadow: 0 8px 18px 4px rgba(0, 0, 0, 0.08),
        0 7px 3px 0 rgba(0, 0, 0, 0.1), 0 0 2px 1px rgba(0, 0, 0, 0.11),
        0 1px 2px 0 rgba(0, 0, 0, 0.07);
    border-radius: 50%;
    transition: margin 0.4s, background 0.4s;
}
.input:checked + .toggle,
.input:checked + .toggle:before {
    background-color: $black;
}

.prime-switch .input:checked + .toggle,
.prime-switch .input:checked + .toggle:before {
    background-color: $whitelabel-blue;
}

.input:checked + .toggle:after {
    margin-left: 20px;
    background-color: $white;
}
.switch-button {
    label:nth-of-type(2) {
        color: $whitelabel-red;
    }
}
