.performance {
    &__col {
        margin-bottom: 66px;
    }

    &__card {
        height: 279px;
        border-radius: 8px;
        box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.04),
            0 3px 9.1px 0 rgba(0, 0, 0, 0.04),
            0 24.3px 36.5px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;

        &.rectangle {
            height: 222px;
            flex-flow: column nowrap;
        }
    }

    &__card-header {
        font-family: $brand-boldest-font;
        font-size: 42px;
        font-weight: bolder;
        line-height: 1em;
        margin: 10px 0;
    }

    .red {
        color: #ff5a5f;

        .iconmoon-icon {
            width: 62px;
            height: 45px;
        }
    }

    .green {
        color: $whitelabel-green;

        .iconmoon-icon {
            width: 42px;
            height: 45px;
        }
    }

    .yellow {
        color: #ffa300;

        .iconmoon-icon {
            width: 44px;
            height: 45px;
        }
    }

    &__table {
        width: 100%;
        font-family: $brand-boldest-font;
        font-size: 20px;
        border-collapse: collapse;
        border-style: hidden;

        th {
            text-align: left;
        }

        td {
            text-align: center;
        }

        td,
        th {
            border: 2px solid #c4c4c4;
            padding: 0.5em;
        }
    }

    @include breakpoint($desktop) {
        &__col {
            margin-bottom: 0;
            &:last-child {
                border-left: 1px solid #c4c4c4;
            }
        }

        &__card {
            &.rectangle {
                flex-flow: row nowrap;
            }
        }

        &__table {
            font-size: 28px;
        }
    }
}
