$prefix: insta-service;

.#{$prefix} {
    &__card_header {
        color: #131415;
        height: 5rem;
        font-size: 3.75rem;
        font-weight: 700;
        
        .top-up {
            background-color: #FFDE66;
        }

        .in-warmer {
            background-color: #F4594F99;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__warmer_sku_category {
        border: 1px solid black;
        font-size: 2rem;
        text-align: left;
        margin: 0.5rem;
        width: 24rem;

        @media (min-width: 1920px) {
            width: 22rem;
        }
    }

    &__warmer_category_name {
        background: #131415;
        color: white;
        padding: 10px;
        text-align: center;
    }

    &__warmer_sku_items {
        padding: 9px 8px;
        color: #303234;
        background: #FFFCF0;
        border-bottom: 1px solid black;
    }

    &__print {
        border: 0;
        width: 30px;
        height: 30px;
        padding: 5px;
        margin-left: 1rem;
    }

    &__warmer_stock_container {
        padding: 0;
        background: #F4594F33;
        height: calc(100vh - 75px);
        flex-grow: 1;
    }

    &__warmer_stock_container_full-screen {
        padding: 0;
        background: #F4594F33;
        height: 100vh;
        flex-grow: 1; 
    }

    &__exit_fullscreen_icon {
        width: 2.75rem;
        height: 2.125rem;
        object-fit: contain;
        padding: 0
    }

    &__fullscreen_btn {
        background-color: transparent;
        border: none;
        margin-right: 16px;
        text-align: right;
    }

    &__insta_service_production_container {
        padding: 0;
        background: #FFF4CC;
        height: calc(100vh - 75px);
        flex: none;
    }

    &__insta_service_production_container_full-screen {
        padding: 0;
        background: #FFF4CC;
        height: 100vh;
        flex: none;
    }

    &__oos-tag {
        font-style: normal;
        font-size: 1.25rem;
        overflow: hidden;
        background-color: red;
        color: white;
        padding: 3px;
        border-radius: 8px;
        min-width: 3.25rem;
        margin-right: 2px;
    }

    &__card_container {
        height: 82vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    &__card_container_full-screen {
        height: 92vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    &__webcam-area {
        background-color: #e6e6e6;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .webcam {
            height: 2px;
            width: 2px;
        }
        .img-to-show {
            height: 12.5rem;
            width: 12.5rem;
            object-fit: fill;
        }
    }
}