$prefix: cashier-pickup;

.#{$prefix} {
    &__dine-in {
        flex-wrap: wrap;
    }

    &__pickup-container {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    &__view-all-orders {
        width: 178px;
        height: 54px;
        padding: 16px;
        border: 1px solid rgba(160, 162, 164, 1) !important;
        border-radius: 8px;
        font-size: 19px;
        font-weight: 700;
        line-height: 20px;
        color: rgba(112, 86, 181, 1);
        background-color: white;
    }
}
