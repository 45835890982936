.secondary-header {
    flex-direction: column;
    justify-content: space-between;

    &__wrapper {
        border-bottom: 2px solid $whitelabel-gray;
    }

    &__staff_member_container {
        display: flex;
        overflow: hidden;
        width: 64vw;
        position: relative;
        white-space: nowrap;
        @media (min-width: 1920px) {
            width: 80vw;
        }
    }
    
    &__staff_member_scroll {
        display: inline-flex;
        white-space: nowrap;
    }
    
    &__staff_member {
        display: inline-flex;
        padding: 5px 7px;
        margin-left: 10px;
        background-color: #ffc700;
        border-radius: 10px;
        color: #7056B5;
        font-size: 25px;
        align-items: center;
    }
    
    &__outlet_manager {
        margin-right: 5px;
        height: 27px;
        width: 27px;
        background-color: #7056B5;
        color: #ffc700;
        border-radius: 50%;
        font-size: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    
    @keyframes scroll-text {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
    
    .scroll-animation {
        animation: scroll-text 30s linear infinite;
    }

    &__view-all-orders {
        width: 178px;
        height: 54px;
        padding: 16px;
        border: 1px solid rgba(160, 162, 164, 1) !important;
        font-size: 19px;
        font-weight: 700;
        line-height: 20px;
        color: rgba(112, 86, 181, 1);
        border-radius: 8px;
        background-color: white;
    }

    &__left {
        margin-bottom: 12px;
        max-width: 82.5vw;

        .date-input {
            flex: 1;
            span {
                display: block;
            }
        }

        &-icon {
            width: 20px;
            height: 20px;
            position: absolute;
            left: 34px;
            cursor: pointer;

            .icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__check-in {
        background-color: $whitelabel-green;
        color: white;
        padding: 10px 36px !important;
    }

    button {
        border: solid 1px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
    }

    input {
        // width: 250px;
        font-size: 14px;
        padding: 23.5px 13px;
        border: solid 1px #d1d1d1;
        background-color: #f9f9f9;
        box-shadow: none;
        font-family: $brand-boldest-font;
        border-radius: 5px;
    }

    &__dropdown {
        position: relative;

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: white;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            right: 0;
            a {
                font-family: $brand-bolder-font;
                text-decoration: none;
                font-size: 16px;
                padding: 20px !important;
                display: block;
            }
        }
        .dropdown-content:hover {
            background-color: $whitelabel-gray;
        }
    }
    &__dropdown:hover .dropdown-content {
        display: block;
    }
    .exit_fullscreen_icon {
        width: 2.75rem;
        height: 2.125rem;
        object-fit: contain;
        padding: 0
    }

    @include breakpoint($mobile) {
        input {
            font-size: 20px;
        }
    }

    @include breakpoint($desktop) {
        flex-direction: row;

        &__left {
            margin-bottom: 0;
        }
    }
}
