$prefix: queue-screen;

h1,
h2,
h3,
h4,
p {
    margin: 0px;
    padding: 0px;
}

.#{$prefix}__page {
    .fullscreen {
        flex: auto;
        display: flex;
        flex-flow: column nowrap;
        overflow-y: auto;
    }
}

.#{$prefix} {
    flex: 1 1 auto;
    background-color: $primary-yellow;
    color: $primary-purple;
    min-height: 100vh;

    &.delay {
        border-color: $whitelabel-red;
    }

    &__queue-screen-main {
        height: 100%;
        overflow: hidden;
    }

    &__preparing {
        width: 40%;
        height: 100vh;
        padding-left: 35px;

        .preparing-title {
            margin-top: 40px;
            color: $primary-purple;
            text-transform: uppercase;
            font-family: 'Barlow', sans-serif;
        }
    }

    &__partition {
        width: 9%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-family: 'Barlow Semi Condensed', sans-serif;

        .popmeals-logo {
            position: absolute;
            bottom: 12px;
        }

        .popmeals-logo-text {
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 58px;
            letter-spacing: 0px;
            text-transform: uppercase;
            font-family: 'Barlow Semi Condensed', sans-serif;
        }

        .popmeals-logo-img {
            margin-top: 8px;
            height: 111px;
            width: 177px;
        }
    }

    &__ready {
        width: 51%;
        position: relative;
        padding-left: 120px;

        .svg-background {
            position: absolute;
            top: 0px;
            right: 0px;
            left: 0px;
            height: 100vh;
            width: 100%;
        }
    }

    &__ready-main {
        position: relative;
        color: $primary-yellow;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ready {
            color: $primary-yellow;
            margin-top: 40px;
            z-index: 0;
            text-transform: uppercase;
            font-family: 'Barlow', sans-serif;
        }
    }

    &__order-blocks {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__order-card {
        width: 336px;
        height: 192px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        border-radius: 150px;
        background-color: white;
        position: relative;
        z-index: 0;
        font-family: 'Barlow Semi Condensed', sans-serif;
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        .pickup-number {
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 43px;
            letter-spacing: 0px;
            text-align: center;
            color: black;
            font-family: 'Barlow Semi Condensed', sans-serif;

            margin-bottom: 0px;
        }

        .pickup-number-modal {
            font-size: 96px;
            font-weight: 600;
            line-height: 115px;
        }

        .customer-name {
            margin: 20px 0px;
            margin-top: 30px;
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: 84px;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: center;
            color: $primary-purple;
            text-transform: uppercase;
            font-family: 'Barlow Semi Condensed', sans-serif;
        }

        .customer-name-modal {
            font-size: 190px;
            font-weight: 700;
            line-height: 228px;
            margin-top: 0px;
        }

        .lower-svg {
            position: absolute;
            width: 107px;
            height: 55px;
            bottom: 0px;
            z-index: -1;
        }

        .lower-svg-modal {
            position: absolute;
            bottom: 0px;
            z-index: -1;
            height: 288px;
            height: 158px;
            width: 288px;
        }

        .order-eta-status {
            z-index: 10;
            margin-bottom: -5px;
            color: white;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: 0px;
            text-align: center;
            text-transform: uppercase;
            font-family: 'Barlow Semi Condensed', sans-serif;
        }

        .order-eta-status-modal {
            z-index: 10;
            margin-bottom: 0px;
            font-size: 70px;
            font-style: normal;
            font-weight: 600;
            line-height: 84px;
        }
    }

    &__order-card-modal {
        height: 518px;
        width: 907px;
        border-radius: 404px;
    }

    &__headline {
        font-family: Barlow;
        font-size: 96px;
        font-style: normal;
        font-weight: 700;
        line-height: 115px;
        letter-spacing: 0px;
        text-align: center;
    }
}
