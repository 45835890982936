$prefix: live-dispatch;
$breakpoint-tablet: 768px;
$breakpoint-ipad: 1280px;

@keyframes red-purple-delay-animation {
    0% {
        background-color: $delay-red;
        border-color: $delay-red;
    }
    49% {
        background-color: $delay-red;
        border-color: $delay-red;
    }
    50% {
        background-color: $primary-purple;
        border-color: $primary-purple;
    }
    100% {
        background-color: $primary-purple;
        border-color: $primary-purple;
    }
}

@keyframes red-black-delay-animation {
    0% {
        background-color: $delay-red;
        border-color: $delay-red;
    }
    49% {
        background-color: $delay-red;
        border-color: $delay-red;
    }
    50% {
        background-color: $whitelabel-black;
        border-color: $whitelabel-black;
    }
    100% {
        background-color: $whitelabel-black;
        border-color: $whitelabel-black;
    }
}

.#{$prefix}__page {
    .fullscreen {
        flex: auto;
        display: flex;
        flex-flow: column nowrap;
        overflow-y: auto;
    }
}

.#{$prefix} {
    text-align: left;
    flex: 1 1 auto;
    font-family: $brand-bolder-font;

    &__button {
        padding: 2rem;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 3rem;
        line-height: 1.375rem;
        text-align: center;
        color: white;
        border: none;
        border-radius: 5px;
        width: 18.75rem;
        &.cancel {
            background-color: $whitelabel-red;
        }

        &.confirm {
            background-color: $whitelabel-green;
            margin-left: 6.25rem;
        }
    }

    &__webcam-area {
        background-color: #e6e6e6;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .webcam {
            height: 2px;
            width: 2px;
        }
        .img-to-show {
            height: 12.5rem;
            width: 12.5rem;
            object-fit: fill;
        }
    }

    &__route-driver-alias-name {
        font-size: 24px;
        font-weight: 700;
    }

    &.delay {
        border-color: $delay-red;
    }
    &__dine-in-orders-container {
        // height: 90vh;
        // overflow-y: auto;
        background-color: $white;
        min-width: 27.5%;
        @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-ipad) {
            // width: 35%;
        }
    }

    &__heading {
        font-family: $brand-bolder-font;
        color: #1a1a1a;
        font-size: 36px;
        font-style: normal;
        font-weight: bold;
        line-height: 2rem;
        letter-spacing: 0px;
        padding: 16px 25px;
    }

    &__dine-in-container {
        // overflow-x: hidden;

        .items-length {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 1.125rem;
            line-height: 1.125rem;
        }
    }

    &__dine-in-table {
        border-collapse: collapse;
        color: #1a1a1a;
        padding-left: 25px;
        gap: 16px;
        flex-direction: column;
        flex-wrap: wrap;

        .order-th {
            padding-left: 1.5rem;
        }

        .items-th {
            padding-left: 1.25rem;
        }
    }

    &__dine-in-orders {
        padding: 0px;
    }

    .dine_in_icon {
        width: 44px;
    }

    .delivery_icon {
        width: 44px;
        height: 25px;
    }

    .take_away_icon {
        width: 44px;
        height: 33px;
    }

    &__dine-in-order {
        border: 3px solid $whitelabel-lighter-black;
        width: 315px;

        @media (min-width: 1300px) { 
            width: 340px;
        }

        &.delay {
            border: 3px solid $delay-red;
        }

        td {
            vertical-align: top;
            padding: 1.5rem 0px;
        }

        .order-info-container {
            width: 100%;
            padding-left: 10px;
            // .take_away_icon {
            //     width: 2.75rem;
            //     height: 2.5rem;
            //     filter: invert(1);
            // }

            // .dine_in_icon {
            //     width: 2.75rem;
            //     height: 1.875rem;
            //     filter: invert(1);
            // }

            .icon-number-name {
                display: flex;

                .number-name {
                    .pick_up_number {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 2rem;
                        letter-spacing: -0.5px;
                        font-family: $brand-bolder-font;
                        @media (min-width: 1920px) { 
                            font-size: 1.25rem;
                        }
                    }

                    .customer_name {
                        margin-top: 0.25rem;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 0.875rem;
                        line-height: 1rem;
                        text-transform: uppercase;
                        font-family: $brand-bolder-font;
                    }

                    .aggregator_short_order_id {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 2rem;
                        letter-spacing: -0.5px;
                        font-family: $brand-bolder-font;
                        @media (min-width: 1920px) { 
                            font-size: 1.25rem;
                        }
                        // font-style: normal;
                        // font-weight: bold;
                        // font-size: 1.5rem;
                        // line-height: 1.125rem;
                        // letter-spacing: -0.5px;
                        // font-family: $brand-bolder-font;
                        // padding-top: 12.5%;
                    }
                }
            }

            .order-amount-due-container {
                padding: 0px 0.5rem;
            }

            .order-amount-due {
                width: 8rem;
                color: black;
                border-radius: 16px;
                font-family: $brand-bolder-font;
                font-style: normal;
                font-weight: bold;
                font-size: 1rem;
                background-color: $primary-yellow;
                text-align: center;
            }
        }

        .order-items-skus {
            display: flex;
            flex-wrap: wrap;

            div:last-child {
                border-bottom: none;
            }
        }

        .order-time-box {
            display: flex;
            flex-direction: row;
            border-radius: 0.31rem;
            justify-content: center;
            align-items: flex-start;

            .print_icon {
                margin-left: 0.62rem;
                height: 1.18rem;
                width: 1.375rem;
                cursor: pointer;
            }

            .order-time {
                margin: auto;
                font-size: 1.25rem;
                font-style: normal;
                letter-spacing: 0px;
                text-align: center;
                width: 60px;
            }
        }
    }

    &__great_job_full_screen_img {
        position: absolute; 
        top: 9rem;
        right: 23.5rem;
        width: 15rem;
        @media (min-width: 1920px) {
            top: 5rem;
        }
    }

    &__great_job_img {
        position: absolute; 
        top: 16rem;
        right: 23.5rem;
        width: 15rem;
        @media (min-width: 1920px) {
            top: 9.5rem;
        }
    }

    &__print_icon {
        height: 1.38rem;
        width: 1.375rem;
    }

    &__routes {
        background-color: $white;
        overflow: auto;
        // height: 20vh;
        min-width: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    &__routes-container {
        padding: 0px 25px 16px;
        flex-direction: row;
        gap: 14px;
        overflow-x: auto;
    }

    &__route-left-content {
        flex-direction: column;
        flex-grow: 4;
        width: 100%;

        > div {
            // flex-grow: 1;
            padding: 5px 5px;
        }
    }

    &__route-number-and-dispatch {
        background-color: $whitelabel-black;
        color: $white;
    }

    &__route {
        display: flex;
        align-items: center;
        color: black;
        min-width: 225px;
        min-height: 100px;
        max-width: 425px;
        border: 3px solid $whitelabel-black;

        .#{$prefix}__route-dispatch {
            height: 62.5px;
            text-align: center;
        }

        .#{$prefix}__route-no {
            width: 45px;
            height: 45px;
            border-radius: 3.125rem;
            display: flex;
            justify-content: center;
            align-items: center;
            /* line-height: 2.25rem; */
            border: 3px solid black;
            font-size: 20px;
        }

        .#{$prefix}__dispatch-no {
            position: relative;
            bottom: 0.75rem;
            background-color: black;
            color: white;
            height: 1rem;
            border-radius: 1.68rem;
            font-size: 0.75rem;
            line-height: 1.125rem;
        }

        .#{$prefix}__dispatch-btn {
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            border-radius: 8px;
            padding: 12px 8px;
            background-color: white;
            color: black;
            cursor: pointer;
            box-shadow: 0 0 2px 2px;
        }

        .#{$prefix}__no_rider_info {
            font-size: 1.75rem;
            font-weight: 500;
            line-height: 1.75rem;
            text-align: center;
            padding: 17px 8px;
            width: 100%;
            height: 62.5px;

            @media (min-width: 1920px) { 
                font-size: 1.5rem;
                font-weight: 700;
            }
        }

        .#{$prefix}__route-timer {
            height: 100%;
            align-items: center;
        }

        .#{$prefix}__rider {
            font-size: 1.5rem;
            margin-left: 0.2em;
            text-align: left;
            text-overflow: ellipsis;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            height: 62.5px;
        }

        &.on_route {
            .#{$prefix}__route-no {
                border-color: $white;
                color: $white;
            }

            .#{$prefix}__dispatch-no {
                background-color: $white;
                color: $whitelabel-black;
            }

            .#{$prefix}__rider {
                color: $white;
            }
        }

        &.delay {
            color: $delay-red;

            .#{$prefix}__route-no {
                border-color: $white;
                color: $white;
            }

            .#{$prefix}__dispatch-no {
                background-color: $white;
                color: $whitelabel-black;
            }
        }
    }

    &__route-bags {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;

        .#{$prefix}__bag-info {
            margin-right: initial;
        }
    }

    &__bags-container {
        // max-height: 90vh;
        // overflow: auto;
        // .bag-container-dine-enabled {
        //     width: 50%;
        //     @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-ipad) {
        //         width: 35%;
        //     }
        // }
    }
    .bag-container-dine-not-enabled {
        width: 75%;
    }

    &__bags {
        flex-direction: row;
        flex-wrap: wrap;
        writing-mode: vertical-lr;
        text-orientation: upright;
        height: 54vh;
        padding-left: 25px;
    }

    &__bag {
        writing-mode: horizontal-tb;

        border: 3px solid $whitelabel-lighter-black;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
        // padding: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0.25rem;
        position: relative;
        width: 260px;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }

    &__bag-info {
        position: relative;
        display: flex;
        align-items: center;
        // margin-right: auto;
        // margin-bottom: 0.5rem;
        height: 40px;
    }

    &__bag-header {
        width: 100%;
        background-color: $whitelabel-lighter-black;
        padding: 5px;
        color: $white;
        justify-content: space-between;

        &.delay {
            background-color: $delay-red;
        }
    }

    &__instaservice {
        border: 3px solid $whitelabel-black;

        &.delay {
            animation: red-black-delay-animation 2s linear infinite;
        }

        &.current-stock {
            border: 3px solid $primary-purple;
        }
    }

    &__instaservice-header {
        width: 100%;
        background-color: $whitelabel-black;
        padding: 10px;
        color: $white;
        justify-content: space-between;

        &.delay {
            animation: red-black-delay-animation 2s linear infinite;
        }

        &.current-stock {
            background-color: $primary-purple;
            justify-content: center;
        }
    }

    &__bag-no {
        position: absolute;
        top: 10px;
        color: black;
        font-size: 20px;
        width: 45px;
        height: 1.125rem;
        text-align: center;
    }

    &__skus_container {
        width: 100%;
    }

    &__skus_container div:last-child {
        border-bottom: none;
    }

    &__cutlery {
        font-size: 1.5rem;
        margin-top: 0.25rem;
    }

    &__pickupTime {
        font-size: 1.5rem;
        font-weight: 700;
    }

    &__countdown {
        font-size: 1.375rem;
        text-align: left;

        &-timer {
            font-size: 1.375rem;
            font-weight: bold;
            text-align: left;
            display: inline-block;
            
            &.delay {
                color: $delay-red;
            }
        }

        &-note {
            font-size: 0.8rem;
            font-weight: 400;
            font-family: Inter;
            text-transform: uppercase;
        }
    }

    &__print {
        border: 0;
        width: 30px;
        height: 30px;
        padding: 5px;
    }

    //modal related classes:
    &__modal {
        writing-mode: horizontal-tb;
        color: $whitelabel-black;
        font-style: normal;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5.5rem 0px;

        .modal-amount-paid {
            color: $whitelabel-red;
        }
    }

    &__print_text {
        font-family: $brand-bolder-font;
        font-size: 4.5rem;
        line-height: 1.37rem;
        text-align: center;
        color: #000000;
        font-weight: bold;
    }

    &__modal_order {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .order_type_text {
            font-family: $brand-bolder-font;
            font-size: 2.25rem;
            line-height: 1.375rem;
            color: black;
            margin-bottom: 3rem;
            margin-top: 2rem;
            font-weight: bold;
        }
    }

    &__print_bag_qr_btn {
        background: $whitelabel-black;
        border-radius: 5px;
        padding: 2rem;
        font-family: $brand-bolder-font;
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.375rem;
        color: #ffffff;
        width: 18.75rem;
        margin: 0.625rem 0px;
        border: none;
    }

    &__cancel_button {
        margin-top: 2.5rem;
        width: 18.75rem;
        padding: 2rem;
        background: #fb275f;
        font-family: $brand-bolder-font;
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.375rem;
        color: #ffffff;
        border: none;
        border-radius: 5px;
    }

    &__printer_fail {
        .print_text {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 4.5rem;
            line-height: 1.375rem;
            text-align: center;
            color: #fb275f;
        }
        .no_printer {
            margin-top: 100px;
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 2.75rem;
            text-align: center;
            color: black;
        }
    }

    &__dismiss_button {
        margin-top: 8.25rem;
        padding: 2rem 15.625rem;
        background: $whitelabel-black;
        font-family: $brand-bolder-font;
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.375rem;
        color: #ffffff;
        border: none;
        border-radius: 5px;
    }

    &__customToast {
        display: flex;
        align-items: center;
        .check_svg {
            width: 1.875rem;
            height: 1.3125rem;
        }
        .message {
            margin-left: 0.75rem;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.75rem;
        }
    }

    .icon-bag {
        height: 35px;
        margin-right: 0.5rem;
        width: 45px;
    }

    .icon-print-medium {
        width: 100%;
        height: 100%;
    }

    .icon-cancel {
        width: 2.93rem;
        height: 2.93rem;
        position: absolute;
        top: -3px;
    }

    .icon-cutlery {
        height: 30px;
        width: 30px;
        transform: scale(1.45) translateY(10px);
        filter: invert(1);
    }

    .icon-cutlery-dine-in {
        height: 20px;
        width: 20px;
        transform: scale(0.9) translateY(-5px);
        filter: invert(1);
    }

    .count-cutlery {
        font-size: 1.4rem;
        transform: translateY(-10px);
        padding-left: 4px;
    }

    &__route-outer-section {
        height: auto;
        width: 100%;
        border-bottom: 3px solid #e5e5e5;
    }

    &__insta_service_production_container {
        padding: 0;
        background: black;
        height: 30vh;
        width: 100%;
        overflow-x: scroll;
        @media (min-width: 1920px) {
            height: 27.5vh;
        }
    }

    &__route-section {
        padding: 0;
        margin: 0;
    }

    &__online-staff-section {
        background-color: white;
        padding: 0;
        margin: 0;
        padding-bottom: 8px;
        border-left: 3px solid #e5e5e5;
    }

    &__riders-container {
        margin-top: 8px;
        max-height: 168px;
        overflow-y: auto;
    }

    &__rider-card {
        max-width: 200px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    &__rider-details {
        padding-left: 8px;
    }

    &__rider-index {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        color: white;
        text-align: center;
        font-weight: 700;
        justify-content: center;
        align-items: center;
        display: flex;
        background: black;
    }

    &__rider-index p {
        border-radius: 50px;
        background-color: $whitelabel-black;
        color: white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 16px;
    }

    &__rider-name {
        max-width: 160px;
        height: 60%;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: 5px;
    }

    &__outlet-partner-name {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 8px;
    }

    &__rider-status {
        float: left;
        height: 40%;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
    }

    &__rider-status.intransit {
        color: #ff642e;
    }

    &__rider-status.available {
        color: #03c875;
    }

    &__rider-status.returning {
        color: #f4594f;
    }

    &__modal_table_row {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    &__modal_button_row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__submit_button {
        padding: 2rem;
        background: #18d665;
        font-family: $brand-bolder-font;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.375rem;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        margin-right: 2.5rem;
    }

    &__modal_table_body {
        font-size: 20px;
        margin: 36px;
        @media (min-width: 1920px) {
            font-size: 24px;
            margin: 48px;
        }
    }

    &__modal_table_title {
        color: var(--Red-Red-Primary, #F4594F);
        font-family: Inter;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__view-all-orders {
        width: 178px;
        height: 39px;
        border: 1px solid rgba(160, 162, 164, 1) !important;
        font-size: 19px;
        font-weight: 700;
        line-height: 31px;
        color: rgba(112, 86, 181, 1);
        border-radius: 8px;
        background-color: white;
        margin-top: 14px;
    }

    &__warmer_sku_category {
        border: 2px solid white;
        font-size: 2rem;
        text-align: left;
        margin: 0 0.5rem;
    }

    &__warmer_category_name {
        background: #131415;
        color: white;
        padding: 0.8rem;
        text-align: center;
    }

    &__warmer_sku_items {
        display: flex;
        padding: 0.25rem 0.25rem;
        color: #303234;
        min-width: 22.5rem;
        border: 1px black;
        font-family: 'InterBold';
        font-size: 1.375rem;
    }

    &__warmer_sku_items_table {
        font-size: 2rem;
        width: 100%;
    }

    &__warmer_item_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      
      &__warmer_item_name {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
      &__oos-tag {
        font-style: normal;
        overflow: hidden;
        background-color: red;
        color: white;
        font-size: 1.25rem;
        padding: 7px 3px;
        border-radius: 8px;
        margin-left: 5px;
        min-width: 5px;
        flex-shrink: 0;
      }

    &__card_header {
        color: white;
        height: 4rem;
        font-size: 2.5rem;
        font-weight: 700;
        text-align: left;
        padding-left: 0.5rem;
        padding-top: 0.5rem;

        @media (min-width: 1920px) {
            height: 4rem;
            font-size: 2.5rem;
        }

        .top-up {
            background-color: black;
        }
    }

    &__card_container {
        height: 25vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
    }
}

.Toastify__toast-container--top-right {
    top: 6.8rem !important;
    right: 1.5rem !important;
    @media (min-width: 1920px) {
        top: 5rem !important;
    }
}

.Toastify__toast-container {
    padding: 0px !important;
    width: 27rem !important;
    @media (min-width: 1920px) {
        width: 25.625rem !important;
    }
}

.Toastify__toast {
    background-color: #131415 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px !important;
    min-height: 0px !important;
    padding: 20px 12px 30px 20px !important;
    border-radius: 1rem !important;
    width: inherit !important;
    box-shadow: none !important;
}

.Toastify__toast-body {
    margin: 0px !important;
    padding: 0px !important;
    display: block !important;
    width: inherit !important;
    color: white !important;
}

.Toastify__close-button {
    align-self: center !important;
    color: white;
}

.Toastify__progress-bar--default {
    background: #03c875 !important;
    height: 12px !important;
}
.animate__animated.animate__slideInDown {
    --animate-duration: 300ms;
}

.popup-bag {
    display: flex;
    font-size: 36px;
    font-weight: 700;
}

.animate__animated.animate__slideOutUp {
    --animate-duration: 300ms;
}
