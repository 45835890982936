.logo {
    position: relative;

    &__text {
        margin-right: 3px;
        margin-bottom: -1px;
        fill: $white;

        &--default-black {
            width: 150px;
            fill: $black;
        }

        &--default-white {
            width: 150px;
            fill: $white;
        }
    }

    &--white {
        path {
            fill: $white;
        }
    }

    &--green {
        path {
            fill: $whitelabel-green;
        }
    }

    &--blue {
        path {
            fill: $whitelabel-blue;
        }
    }

    &--red {
        path {
            fill: $whitelabel-red;
        }
    }

    &--orange {
        path {
            fill: $whitelabel-orange;
        }
    }

    &--purple {
        path {
            fill: $whitelabel-purple;
        }
    }
}
