.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-height: 80vh;
    background-color: white;
    border-radius: 5px;
    overflow: auto;
    padding: 20px;
    z-index: 999;

    &__backdrop {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
    }
}
