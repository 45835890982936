$prefix: pick-mode;
h1,
h2,
h3,
h4,
h5,
p {
    margin: 0px;
    padding: 0px;
}
.#{$prefix}__page {
    .fullscreen {
        flex: auto;
        display: flex;
        flex-flow: column nowrap;
        overflow-y: auto;
    }
}

.#{$prefix} {
    background-color: $whitelabel-gray;
    flex: 1 1 auto;

    &__main {
        display: flex;
    }
    &__one-screen {
        width: 50%;
        min-height: 95vh;
        padding: 0px 16px;
        .title {
            margin-top: 20px;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 22px;
            color: $whitelabel-black;
            text-align: left;
        }
    }
    &__cycles {
        border-right: 4px solid #dfe0e1;
    }
    &__meal-card {
        background-color: white;
        margin: 16px 0px;
        padding: 16px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
            0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        min-height: 205px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .customer-name-order-num {
            margin: 0px 8px 8px 8px;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
            color: $whitelabel-black;
            height: 22px;
        }
    }
    &__meal-type {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__one-item {
        width: 128px;
        height: 128px;
        border: 2px solid #dfe0e1;
        margin: 8px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
            0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        font-style: normal;
        font-family: Inter;
        text-align: center;
        color: $whitelabel-black;

        &.delay {
            background-color: $whitelabel-red;
            color: white;
        }
        &.picked {
            border: 2px dashed #dfe0e1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .time {
            padding: 12px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 22px;
            border-bottom: 2px solid #dfe0e1;
            margin-bottom: 0px;
        }
        .sku {
            margin-bottom: 0px;
            padding: 16px 0px;
            font-weight: bold;
            font-size: 40px;
            line-height: 38px;
        }
    }
}
