$prefix: warmer-screen;

.#{$prefix} {
    &__warmer_card {
        margin: 1rem;
        width: 23.6%;
        border: 5px solid $warmer-color;
    }

    &__card_header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 5px solid $warmer-color;
        background-color: $warmer-color;
        padding: 0.875rem 1.125rem 0.875rem 0.75rem;
    }

    &__warmer_name {
        font-family: $brand-bolder-font;
        font-style: normal;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 1.5rem;
        color: $white;
        text-transform: uppercase;
        max-width: 14rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
    }

    &__skus_container {
        display: flex;
        flex-wrap: wrap;
        background-color: rgba(255, 100, 46, 0.25);
        padding: 0.5rem 0.3125rem;
    }

    &__sku_card {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem;
        width: 12.17rem;
        max-width: 12.37rem;
        height: 4rem;
    }
    &__card_sku {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 2.125rem;
        line-height: 2rem;
        color: $white;
        background-color: $warmer-color;
        border-radius: 0.625rem 0px 0px 0.625rem;
        width: 6.5rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__card_quantity_cont {
        color: $white;
        background-color: #303234;
        padding: 0.5rem 1rem;
        border-radius: 0px 0.625rem 0.625rem 0px;
        width: 5.875rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__quantity_x {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.75rem;
        line-height: 2rem;
    }
    &__quantity {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 2.125rem;
        line-height: 2rem;
    }
}
