.loading {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: black !important;
    opacity: 0.3;
    z-index: 1000 !important;
    .loading-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .loader {
        z-index: 999 !important;
    }
}
