$prefix: production__;

.production {
    &__table-wrapper {
        margin-bottom: 100px;
    }

    &__table {
        overflow-y: hidden;
        white-space: nowrap;
        width: 100%;
        padding-right: 0;

        &.show {
            padding-right: 120px;
        }

        &.table-content::-webkit-scrollbar {
            display: none;
        }

        &.table-footer {
            position: fixed;
            bottom: 0;
            z-index: 1;
            background-color: white;
            border-top: 2px solid $whitelabel-gray;
        }
    }

    &__column {
        display: inline-block;
        width: 300px;
        text-align: center;
        font-size: 24px;
        vertical-align: top;

        &.single-cycle {
            width: 100%;
            margin-bottom: 190px;
        }

        &.total {
            width: 120px;
            background-color: white;
            position: absolute;
            z-index: 2;
            right: -115px;
            transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
                -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

            &:before {
                content: '';
                position: fixed;
                width: 120px;
                height: 100vh;
                background-color: white;
                border-left: 2px solid $whitelabel-gray;
                right: -115px;
                top: 0;
                z-index: -1;
                transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
                    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
            }

            &.show {
                right: 0;

                &:before {
                    right: 0;
                }
            }

            .meal-total-tab {
                width: 60px;
                height: 60px;
                position: fixed;
                top: 160px;
                right: 3px;
                background-color: white;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border: 2px solid $whitelabel-gray;
                border-right: 0;
                padding: 5px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
                    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

                &.show {
                    right: 118px;
                }

                .icon {
                    height: 20px;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .#{$prefix}column-header {
                background-color: white;
                border-left: 2px solid $whitelabel-gray;
            }

            .#{$prefix}column-body {
                margin-top: 309px;
                padding: 15px 0;
                border-right: 0;
            }

            .#{$prefix}cell {
                padding: 15px;

                &.grand-total {
                    margin-top: 15px;
                    padding: 20px;
                    background-color: #f3f3f3;
                    position: fixed;
                    bottom: 0;
                    right: -115px;
                    width: 120px;
                    border-left: 2px solid $whitelabel-gray;
                    border-top: 2px solid $whitelabel-gray;
                    transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
                        -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

                    &.show {
                        right: 0;
                    }
                }
            }
        }
    }

    &__column-header {
        background-color: #eeeeee;
        padding: 10px;
        margin-bottom: 20px;
        font-size: 20px;
        font-family: $brand-boldest-font;
    }

    &__column-body {
        padding: 15px 34px;

        &:not(:last-child) {
            border-right: 2px solid #d8d8d8;
        }
    }

    &__cycle-information {
        position: relative;

        &.single-cycle {
            position: relative;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            max-width: 960px;
            margin: 0 auto;
        }
    }

    &__cycle-information-loader {
        max-width: 75px;
        max-height: 75px;
    }

    &__cycle-information-entry {
        padding: 8px;
        border-radius: 5px;
        white-space: normal;
        margin-bottom: 8px;

        &.oven-type {
            background-color: #b6b6b6;
        }

        &.pick-time {
            background-color: #ffd1d1;
        }

        &.start-time {
            background-color: #d1d5ff;
        }

        &.ready-time {
            background-color: #86e4ac;
        }

        &-label {
            font-size: 18px;
            margin-bottom: 0;
        }

        &-time {
            font-family: $brand-boldest-font;
            font-size: 28px;
            margin-bottom: 0;
        }

        &.single-cycle {
            flex: 1 0 0;
            padding: 20px;
            background: 0;

            &.active-countdown {
                background-color: #eeeeee;

                .#{$prefix}time-frame-time {
                    font-size: 52px;
                    color: red;
                }
            }
        }
    }

    &__line {
        position: relative;
        top: 0;
        bottom: 0;
        left: 50%;
        border-left: 2px dashed #adadad;
        height: 40px;
        width: 0;
    }

    &__cell {
        position: relative;
        padding: 8px;
        font-family: $brand-boldest-font;
    }

    &__cell-meal {
        position: absolute;
        top: 50%;
        padding: 7px;
        display: inline-block;
        text-align: right;
        z-index: 1;
        transform: translate(-100%, -50%);
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: initial;
        max-width: 120px;

        &.empty {
            opacity: 0.35;
        }
    }

    &__cell-total {
        position: absolute;
        padding: 7px;
        text-align: left;

        &.empty {
            opacity: 0.35;
        }
    }

    &__cell-trays {
        background-color: #d34a7d;
        color: $white;
        border-radius: 5px;
        width: 100px;
        padding: 7px 10px;
        display: inline-block;
        position: relative;

        &-text {
            display: block;
        }

        &.empty {
            background-color: #dbdbdb;
            opacity: 0.35;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: #f4f4f4;
            border-radius: 50%;
            top: 12px;
            left: -15px;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: #f4f4f4;
            border-radius: 50%;
            top: 12px;
            right: -15px;
        }

        &.single-cycle {
            &:after,
            &:before {
                content: none;
            }

            &.pick {
                background-color: #f26865;
                text-align: center;

                &.sku {
                    width: 80px;
                }

                &.quantity {
                    width: 120px;
                }

                &.strikethrough {
                    background-color: $whitelabel-green;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #5c7080;
                        border-radius: 0;
                    }
                }
            }
        }
    }

    &__cell-row {
        font-size: 24px;

        &.even {
            background-color: $white;
        }

        &.odd {
            background-color: #f4f4f4;
        }
    }

    &__checkbox {
        display: inline-block;
    }

    &__column-footer {
        font-family: $brand-boldest-font;
        padding: 20px;
    }

    &__single-cycle-pick {
        margin-bottom: 160px;

        .btn-wrapper {
            margin-top: 30px !important;
        }
    }

    &__single-cycle-total {
        font-family: $brand-boldest-font;
        padding: 16px;
    }

    &__nav-bar {
        font-family: $brand-boldest-font;
        font-size: 20px;
        border-top: 2px solid $whitelabel-gray;
        padding: 20px;
        display: flex;
    }

    &__nav {
        display: flex;
        cursor: pointer;

        &:hover {
            .#{$prefix}nav-btn {
                background-color: $whitelabel-green;

                .icon {
                    fill: $white;
                }
            }
        }
    }

    &__nav-btn {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #ededed;
        margin: 0 15px;

        .icon {
            height: 50px;
            width: 20px;
        }

        &.drawer-close {
            position: absolute;
            top: 20px;
            right: 0;
            cursor: pointer;
        }
    }

    &__nav-label {
        .production {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);

            &.prev {
                text-align: left;
            }

            &.next {
                text-align: right;
            }
        }
    }

    &__drawer {
        position: fixed;
        width: 100%;
        bottom: 0;
        padding: 50px 35px;
        background-color: $white;
        min-height: 170px;
        max-height: 100%;
        overflow: auto;
        z-index: 10;
        transform: translateY(100%);
        transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
            -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

        &.open {
            transform: translateY(0);
        }
    }

    &__drawer-button {
        width: 100px;
        height: 50px;
        border-radius: 5px;
        border: solid 1px #d1d1d1;
        background-color: #f9f9f9;
        margin: 5px;
        line-height: 50px;
        font-size: 20px;
        font-family: $brand-boldest-font;
        cursor: pointer;

        &.font-small {
            font-size: 14px;
            width: 100%;
        }

        &.selected {
            background-color: $whitelabel-green;
            border-color: $whitelabel-green;
            color: $white;

            &:hover {
                background-color: #f9f9f9;
                color: $whitelabel-green;
            }
        }

        &.unchecked {
            background-color: #f26865;
            border-color: #f26865;
            color: $white;

            &:hover {
                background-color: #f9f9f9;
                color: #f26865;
            }
        }

        &.current {
            color: $black;
        }

        &:hover {
            border-color: $whitelabel-green;
            color: $whitelabel-green;
        }
    }

    &__drawer-mask {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }
}

.dash {
    border: 0 none;
    border-top: 2px dashed #adadad;
    background: none;
    height: 0;
    display: inline-block;
    width: 0;
    margin: 9px;

    @include breakpoint($mobile) {
        width: 42px;
    }
}

.separator {
    width: 1px;
    height: 30px;
    margin: 0 15px;
    background-color: #d1d1d1;
    display: none;

    @include breakpoint($mobile) {
        display: block;
    }
}

.bp3-icon-calendar {
    padding: 11px;
}

.bp3-input-group .bp3-input:not(:first-child) {
    padding-left: 40px;
}

.no-data {
    font-size: 52px;
    text-align: center;
    border-radius: 5px;
    font-family: $brand-boldest-font;
    color: rgba(0, 0, 0, 0.2);
    padding: 250px 100px;
}

.btn {
    &-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
    }

    &-production {
        margin: 0 10px;
        width: 180px;
    }

    &-pick-mode {
        margin: 34px;
        max-width: 560px;
    }

    @include breakpoint($mobile) {
        &-wrapper {
            align-items: center;
        }

        &-production {
            margin: 0 15px 0 0;
        }
    }
}
