$prefix: dispatch_scan;

.#{$prefix} {
    &__container {
        border-radius: 25px;
        box-shadow: 0px 20px 20px 0px #00000040;
    }

    &__scan_box {
        position: absolute;
        width: 25rem;
        height: 56.25rem;
        right: 1rem;
        top: 15.5rem;
        border-radius: 25%;
        @media (min-width: 1920px) {
            width: 25rem;
            top: 9.75rem;
            height: 57rem;
        }
    }

    &__scan_box_full_screen {
        position: absolute;
        width: 25rem;
        height: 57rem;
        right: 1rem;
        top: 8.25rem;
        border-radius: 25%;
        @media (min-width: 1920px) {
            width: 25rem;
            top: 5.25rem;
            height: 58rem;
        }
    }

    &__header {
        width: 100%;
        height: 5.1rem;
        font-family: Inter;
        font-weight: 900;
        font-size: 2.25rem;
        line-height: 1.1;
        color: #ffffff;
        text-align: center;
        padding: 1.5rem 0px;
    }

    &__bag {
        height: 700px;
    }

    &__warmer {
        height: 700px;
    }

    &__idle {
        height: 700px;
    }

    &__instaservice {
        height: 700px;
    }

    &__takeaway {
        height: 700px;
    }

    &__aggregators {
        background-color: #303234;
        height: 700px;
    }

    &__virtual_brand {
        background-color: #4154FF;
        height: 700px;
    }

    &__clock_in {
        background-color: #fec700;
        height: 700px;
    }

    &__clock_out {
        background-color: #03c875;
        height: 700px;
    }

    &__invalid_qr {
        background-color: #f4594f;
        height: 700px;
    }

    &__scanner_box_view {
        padding: 0px;
    }

    &__scanner_box_header_row {
        width: 22.1rem;
        margin: auto;
        position: relative;
        background: #FFFFFF;
        display: flex;
        border-radius: 10px;
        .icon_order_type {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 4rem;
            position: relative;
            margin-left: 0.5rem;

            .icon {
                width: 100%;
            }

            .take_away_icon {
                width: 80%;
            }
            .delivery_icon {
                width: 70%;
                position: absolute;
                top: 0.5rem;
            }
            .icon_text {
                position: absolute;
                top: 60%;
                left: 50%;
                transform: translate(-55%, -50%);
                color: black;
                font-size: 1.75rem;
                font-weight: bold;
                text-align: center;
            }
            .delivery_icon_text {
                position: absolute;
                top: 80%;
                left: 50%;
                transform: translate(-55%, -50%);
                color: black;
                font-size: 1.75rem;
                font-weight: bold;
                text-align: center;
            }
            .take_away_bag_scan_icon_text {
                position: absolute;
                top: 60%;
                left: 50%;
                transform: translate(-55%, -50%);
                color: black;
                font-size: 1.75rem;
                font-weight: bold;
                text-align: center;
            }
            .delivery_bag_scan_icon_text {
                position: absolute;
                top: 80%;
                left: 50%;
                transform: translate(-55%, -50%);
                color: black;
                font-size: 1.75rem;
                font-weight: bold;
                text-align: center;
            }
        }

        .order_info {
            margin-left: 0.5rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
        }
    }

    &_title {
        height: 174px;
        font-family: Inter;
        font-size: 6rem;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1a1a1a;

        &.text--white {
            color: $white;
        }
    }

    &__order_type_text {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        color: #131415;
        text-transform: uppercase;
        padding-left: 8px;
    }

    &__user_info {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 2.3rem;
        color: #131415;
        margin-top: 0.25rem;
        @media (min-width: 1920px) {
            font-size: 2.4rem;
        }
    }

    &__bag_user_info {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 2.3rem;
        color: #131415;
        margin-bottom: 0.25rem;
        @media (min-width: 1920px) {
            font-size: 2.4rem;
        }
    }

    &__scanned_info {
        font-family: Inter;
        font-style: normal;
        text-align: left;
        font-size: 1.5rem;
        color: #131415;
        margin-bottom: 0.25rem;
    }

    &__bag_scanned_info {
        font-family: Inter;
        font-style: normal;
        text-align: left;
        font-size: 1.5rem;
        color: #131415;
        margin-top: 0.25rem;
    }

    &__bag_number {
        font-family: Inter;
        font-style: normal;
        font-weight: 900;
        font-size: 50px;
        line-height: 60px;
        text-align: right;
        color: #131415;
        &.text--white {
            color: $white;
        }
    }

    &__sku_text {
        font-family: Inter;
        font-style: normal;
        font-weight: 900;
        font-size: 50px;
        line-height: 61px;
        text-align: center;
        color: #131415;
        position: absolute;
        top: 40px;
        right: 0px;

        &.text--white {
            color: $white;
        }
    }

    &_subtitle {
        height: 44px;
        font-family: Inter;
        font-size: 36px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #131415;

        &.text--white {
            color: $white;
        }
    }

    &__scan_error {
        color: red;
        font-size: 50px;
    }

    &__icon {
        height: 35px;
        width: 64px;
        margin: 0px 0.5rem;
        @media (min-width: 1920px) {
            height: 43px;
        }
    }

    &_print {
        float: right;
        position: relative;
        top: 70px;
        width: 163px;
        height: 59px;
        &_icon {
            width: 29px;
            height: 15px;
        }

        &_off {
            width: 180px;
            height: 180px;
            margin-top: 114px;
        }
    }

    &__meal_images_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }

    &__meal_img_title_container {
        margin: 24px 0px;
    }

    &__expected {
        width: 22.1rem;
        height: 21.25rem;
        margin: 1.5rem 1.5rem;
    }

    &__virtual_brand_info {
        background-color: #F4594F;
        border-radius: 16px;
        padding: 16px 24px;
        margin-top: -150px;
        margin-left: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: white;
        position: absolute;
    }

    &__capture_img_section {
        width: 22.1rem;
        height: 19.25rem;
        margin: 0.75rem 1.5rem;
        @media (min-width: 1920px) {
            margin: 1rem 0px 1.75rem 1.5rem;
        }
    }

    &__title_container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 10px 10px 0px 0px;

        .checkmark_svg {
            margin-right: 22px;
        }

        .title {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 2rem;
            color: #1a1a1a;
            text-transform: uppercase;
            padding: 10px 0px;
            @media (min-width: 1920px) {
                font-size: 32px;
                line-height: 39px;
            }
        }
    }

    &__qr_code_response_container {
        text-align: left;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #1a1a1a;
        padding-top: 1rem;

        .title {
            padding-left: 42rem;
        }
    }

    &__meal_img {
        width: 100%;
        height: 18rem;
        display: flex;
        justify-content: center;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 10px 10px;
    }

    &__error_state {
        width: 100%;
        height: 17rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;
        border-radius: 0px 0px 10px 10px;
        @media (min-width: 1920px) {
            height: 18rem;
        }

        .text {
            margin-top: 20px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 2rem;
            text-align: center;
            color: white;

            @media (min-width: 1920px) {
                font-size: 32px;
                line-height: 39px;
            }
        }
        .virtual_brand_info {
            background-color: #F4594F;
            border-radius: 16px;
            padding: 16px 24px;
            margin-top: 10px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            text-align: center;
            color: white;
        }
    }

    &__upload_picture_chip {
        position: absolute;
        bottom: 16px;
        right: 16px;
        background-color: #303234;
        color: white;
        border-radius: 102px;
        padding: 12px 16px;

        .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
        }
    }

    &__sku_items_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 25rem;
        overflow-y: scroll;
        margin-top: 1.5rem;
    }

    &__order_item_card {
        width: 22.1rem;
        height: 3.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        color: $black;

        &.with-border-bottom {
            border-bottom: 3px solid $black !important;
        }

        @media (min-width: 1920px) {
            height: 2.5rem;
        }
    }

    &__sku_items {
        width: 88%;
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        color: $black;
        font-size: 1.375rem;
        font-weight: bold;
        font-family: $brand-bolder-font;
        padding-left: 0.5rem;

        &.on_route {
            background-color: $route-color;
        }
        &.with-border-bottom {
            border-bottom: 3px solid $black !important;
        }
    }

    &__sku_items_header {
        width: 22.1rem;
        height: 3.25rem;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $black;
        font-size: 1.75rem;
        line-height: 2.75rem;
        font-weight: bold;
        font-family: $brand-bolder-font;
        border-radius: 10px 10px 0px 0px;
    }
}
