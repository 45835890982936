.inventory-adjustment {
    &__skus_of_one_sku_letter {
        margin: 30px 0px;
    }
    &__sku_letter_top_row {
        display: flex;
        justify-content: space-around;
        align-items: center;
        > * {
           width: 25%;
           text-align: center; 
        }
    }
    &__one_sku_row {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &__sku_toggle_sell_quantity {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__sku_meal_availability_modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items: space-between;
    }

    &__modal_title {
        font-size: 22px;
        text-align: center;
    }

    &__modal_desc {
        font-size: 16px;
        text-align: left;
    }

    &__meal_availability_inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__marketing_modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items: space-between;
        padding: 10px;
    }

    &__availability_input_number {
        min-width: 216px;
        .input_number {
            margin-left: 10px;
            width: 50px;
            height: 40px;
        }
    }

    &__marketing_input {
        min-width: 216px;
        padding: 10px;

        .marketing_number {
            width: 50px;
            height: 40px;
            margin: 10px 0 10px 10px;
        }
    }

    &__reasons {
        display: flex;
        flex-wrap: wrap;
        border: 2px solid gray;
        border-radius: 5px;
    }

    &__selected_reason {
        display: flex;
        align-items: center;
        padding: 5px;
        background-color: gray;
        margin: 5px;
        color: white;
        border-radius: 5px;
    }

    &__reason_remove_btn {
        margin-left: 3px;
    }

    &__availability_dropdown_container {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .reason_text {
            margin-right: 10px;
        }
    }

    &__dropdown {
        cursor: pointer;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        text-transform: capitalize;
        padding: 10px 15px;
        border-radius: 8px;
        background-color: $whitelabel-gray;
        border: none;

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: white;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 100;
            right: 0;
            a {
                font-family: $brand-bolder-font;
                text-decoration: none;
                font-size: 16px;
                padding: 20px !important;
                display: block;
                position: relative;
                z-index: 100;
            }
        }
        .dropdown-content:hover {
            background-color: $whitelabel-gray;
        }
    }

    &__dropdown:hover .dropdown-content {
        display: block;
    }

    &__meal_availability_btns {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    &__marketing_modal_btns {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    &__sku_button {
        height: 50px;
        border-radius: 5px;
        border: solid 1px #d1d1d1;
        background-color: #f9f9f9;
        line-height: 50px;
        font-size: 20px;
        font-family: $brand-boldest-font;
        cursor: pointer;

        &.font-small {
            font-size: 14px;
        }

        &.selected {
            background-color: $pop-yellow;
            border-color: $black;
            color: $black;

            &:hover {
                background-color: $pop-purple;
                border-color: $pop-purple;
                color: $white;
            }
        }

        &.unchecked {
            background-color: #f26865;
            border-color: #f26865;
            color: $white;

            &:hover {
                background-color: #f9f9f9;
                color: #f26865;
            }
        }

        &.current {
            color: $black;
        }

        &:hover {
            border-color: $whitelabel-green;
            color: $whitelabel-green;
        }
    }

    &__drawer_button {
        min-width: 100px;
        height: 50px;
        border-radius: 5px;
        border: solid 1px #d1d1d1;
        background-color: #f9f9f9;
        margin: 5px;
        line-height: 50px;
        font-size: 20px;
        font-family: $brand-boldest-font;
        cursor: pointer;

        &.font-small {
            font-size: 14px;
        }

        &.selected {
            background-color: $pop-yellow;
            border-color: $black;
            color: $black;

            &:hover {
                background-color: $pop-purple;
                color: $white;
            }
        }

        &.unchecked {
            background-color: #f26865;
            border-color: #f26865;
            color: $white;

            &:hover {
                background-color: #f9f9f9;
                color: #f26865;
            }
        }

        &.current {
            color: $black;
        }

        &:hover {
            border-color: $pop-purple;
            color: $whitelabel-green;
        }
    }

    &__highlight_text {
        color: $whitelabel-red;
    }

    &__text_align_left {
        text-align: left;
    }

    &__marketing_activity {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        line-height: 50px;

        .btn {
                background-color: $pop-yellow;
                border-radius: 5px;
                border: solid 1px;
                padding: 6px;
                line-height: 0px;
            }
    }
}
