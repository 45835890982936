$prefix: orders-kitchen;

@keyframes black-white-neworderanimation {
    0% {
        background-color: $whitelabel-black;
        color: white;
    }
    49% {
        background-color: $whitelabel-black;
        color: white;
    }
    50% {
        background-color: white;
        color: $whitelabel-black;
    }
    100% {
        background-color: white;
        color: $whitelabel-black;
    }
}

@keyframes black-yellow-neworderanimation {
    0% {
        background-color: $whitelabel-black;
        color: $primary-yellow;
    }
    49% {
        background-color: $whitelabel-black;
        color: $primary-yellow;
    }
    50% {
        background-color: $primary-yellow;
        color: $whitelabel-black;
    }
    100% {
        background-color: $primary-yellow;
        color: $whitelabel-black;
    }
}

@keyframes red-black-delay-animation {
    0% {
        background-color: $delay-red;
        border-color: $delay-red;
    }
    49% {
        background-color: $delay-red;
        border-color: $delay-red;
    }
    50% {
        background-color: $whitelabel-black;
        border-color: $whitelabel-black;
    }
    100% {
        background-color: $whitelabel-black;
        border-color: $whitelabel-black;
    }
}

.#{$prefix} {
    &__warmer_stock_container {
        height: 100vh;
        width: 24.4%;
        margin-top: 1rem;
    }

    &__orders {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: calc(100vh - 70px);
        max-height: calc(100vh - 70px);
        overflow-y: hidden;
        overflow-x: auto;
        background-color: $whitelabel-gray;
        padding: 1rem;
        align-items: center;
        align-content: flex-start;
    }

    .full-screen {
        min-height: 100vh;
    }

    &__order_card {
        width: 23.6%;
        background: $white;
        border: 5px solid $whitelabel-black;
        margin: 1rem;        
    }

    &__order_card.delay {
        border: 5px solid $delay-red;
    }

    &__order_card_take_away {
        border: 5px solid $primary-yellow;
    }
    &__order_card_dine_in {
        border: 5px solid $primary-yellow;
    }

    &__card_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border-bottom: 5px solid #a0a2a4;
        padding: 0.875rem 1.125rem 0.875rem 0.75rem;
        background-color: $black;
        color: $white;
    }

    &__order_card_warmer {
        border: 5px solid $whitelabel-black;

        &.current-stock {
            border: 5px solid $primary-purple;
            height: 75vh;
            max-height: 75vh;
            overflow-y: hidden;
            background-color: $primary-purple;
            margin-left: 1rem;
        }
    }

    .order_header_type_take_away,
    .order_header_type_dine_in {
        background-color: $primary-yellow;
        // border-bottom: 5px solid $primary-yellow;
        color: $black!important;

        img {
            filter: none;
        }
    }

    .order_header_type_warmer {
        background-color: $whitelabel-black;
        color: $white!important;

        &.current-stock {
            background-color: $primary-purple;
            justify-content: center;
        }
    }

    .new-order-black-white {
        background-color: $whitelabel-black;
        color: $white;
        animation: black-white-neworderanimation 1s linear infinite;
    }

    .new-order-black-yellow {
        background-color: $primary-yellow;
        color: $whitelabel-black;
        animation: black-yellow-neworderanimation 1s linear infinite;
    }

    .delay-red-black {
        animation: red-black-delay-animation 2s linear infinite;
    }

    .delay {
        background-color: $delay-red;
        // border: 5px solid $delay-red;
        color: $white;

        img {
            filter: none;
        }
    }

    &__icon_num_name {
        display: flex;
        align-items: center;        

        .delievery_icon {
            width: 2.125rem;
            height: 1.875rem;
            margin-right: 0.625rem;
            filter: invert(1);
        }

        .take_away_icon {
            width: 2.75rem;
            height: 2.125rem;
            margin-right: 0.625rem;
            object-fit: contain;
            filter: invert(1);
        }

        .aggregator_icon {
            width: 2.75rem;
            height: 2.125rem;
            margin-right: 0.625rem;
            object-fit: contain;
        }

        .dine_in_icon {
            width: 2.75rem;
            height: 1.875rem;
            margin-right: 0.625rem;
            filter: invert(1);
        }
    }

    &__order_number_customer_name {
        font-family: $brand-bolder-font;
        font-style: normal;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 1.5rem;
        // color: $whitelabel-black;
        text-transform: uppercase;
        max-width: 14rem;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__time_elapse {
        font-family: $brand-bolder-font;
        font-style: normal;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 1.5rem;
        // color: $whitelabel-black;
    }

    &__skus_container {
        display: flex;
        flex-wrap: wrap;
        background: $white;
    }

    &__skus_container div:last-child {
        border-bottom: none;
    }

    .sku_container_take_away,
    .sku_container_dine_in,
    .sku_container_warmer {
        background-color: #fff4cc;
    }

    .sku_container_warmer {
        margin: 8px 16px;
    }

    .sku_container_delay {
        background-color: $white;
    }

    &__sku_item {
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0.625rem 1rem 0.7rem;
        background-color: $white;
        color: $black;
        border-bottom: 3px solid $black;

        .sku {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 2.125rem;
            overflow: hidden;
            width: 75%;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .qty {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 2.125rem;
            overflow: hidden;
            text-align: right;
            
        }

        .sku-tag {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 1.2rem;
            overflow: hidden;
            width: 10%;
            background-color: red;
            color: white;
            padding-top: 5px;
            padding-bottom: 5px;
            border-radius: 8px;
            min-width: 30px;
        }

        .aggregator_name {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem;
            overflow: hidden;
        }

        .checkmark-svg {
            margin-left: 1rem;
            width: 3rem;
            height: 2.125rem;
            min-width: 3rem;
            min-height: 2.125rem;
        }

        /*
        .warmer_text {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 3rem;
            line-height: 2rem;
            color: $whitelabel-black;
            margin-left: 1.875rem;
        }
         */

        &.in_production {
            // background-color: #303234;
            color: $black;
        }

        &.global {
            background-color: #80FF00;
        }

        /*
        &.in_warmer {
            background-color: #fcab3f;
        }
         */

        &.on_route {
            background-color: #80FF00;
        }
    }


    &__order_item_card {
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        color: $black;

        &.with-border-bottom {
            border-bottom: 3px solid $black !important;
        }
    }

    &__sku_items {
        width: 85%;
        height: 100%;
        padding: 0.7rem 0.625rem 1rem 0rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        color: $black;

        .sku {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 2.125rem;
            overflow: hidden;
        }

        .aggregator_name {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem;
            overflow: hidden;
        }

        .checkmark-svg {
            margin-left: 1rem;
            width: 3rem;
            height: 2.125rem;
            min-width: 3rem;
            min-height: 2.125rem;
        }

        .logo-svg {
            margin-left: -1rem;
            margin-bottom: 1rem;
        }

        &.in_production {
            color: $black;
        }

        &.global {
            background-color: #80FF00;
        }

        &.on_route {
            background-color: #80FF00;
        }

        &.with-border-bottom {
            border-bottom: 3px solid $black !important;
        }
    }

    &__in_cart_order_card {
        position: absolute;
        bottom: 0;
        left: 2rem;
        width: 23.6%;
        background-color: $in-cart-color;
        min-height: 25vh;
    }

    &__in_car_order_card_header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 5px solid $in-cart-color;
        padding: 0.875rem 1.125rem 0.875rem 0.75rem;
    }

    &__card_order_mode {
        border-radius: 0px 0.625rem 0.625rem 0px;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
    }

    &__in_cart_order_name {
        font-family: $brand-bolder-font;
        font-style: normal;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 1.5rem;
        color: black;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
    }

    &__in_cart_order_skus_container {
        display: flex;
        flex-wrap: wrap;
        padding: 8px 16px;
    }

    &__sku_card {
        margin: 0.5rem;
        width: 100%;
        height: 4rem;
        background-color: white;
        color: black;
    }

    &__card_sku {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 2.125rem;
        line-height: 2rem;
        border-radius: 0.625rem 0px 0px 0.625rem;
        margin-left: 10px;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
    }

    &__card_quantity_cont {
        border-radius: 0px 0.625rem 0.625rem 0px;
        width: 4.25rem;
        height: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
    }

    &__quantity_x {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.75rem;
        line-height: 2rem;
    }
    
    &__quantity {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 2.125rem;
        line-height: 2rem;
    }
}
